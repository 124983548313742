import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '@environments/environment';
import { PreappRoutes } from '@common/models/routes/PreappRoutes';
@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})

export class BannerComponent implements OnInit {

  public imgs = ['1', '2', '3', '4'];
  public path = '../../../assets/images/banner-imgs/smart-preaprueba-';
  public random = null;
  public src = null;
  private preapp_routes = new PreappRoutes().routes;
  private baseHref = new PreappRoutes().baseHref;
  private base = `${environment.slUrl}/${this.baseHref}`;
  // public simulate_url = `${this.base}/${this.preapp_routes.purchase_simulation}`;
  public pa_url = `${this.base}/${this.preapp_routes.purchase_direct_preapprover}`;

  constructor( private sanitizer: DomSanitizer ) { }

  ngOnInit() {
    this.createRandomImage();
  }
  createRandomImage() {
    this.random = this.imgs[Math.floor(Math.random() * this.imgs.length)];
    if (this.random === 0) {
      this.random = 1;
    }
    this.src = this.path + this.random + '.jpg';
    this.sanitizer.bypassSecurityTrustStyle(this.src);
  }

  goToPreapprover(){
    window.open(this.pa_url, '_self');
  }
}
