<section class="row banner-component">
    <div class="col-12 col-md-4 banner-image" [style.background-image]="'url('+src+')'">
    </div>
    <div class="col-12 col-md-8 gradient-bg padding-quieres">
        <h2 class="white-text text-left"> Descubre cuánto te prestamos<br /> para comprar tu casa. </h2>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-12 text-left pd-l-0 m-t-20-sm">
                <button type="button col-12" class="btn btn-secondary btn-large" (click)="goToPreapprover()">
                  Preaprueba tu crédito
                </button>

            </div>
            <div class="col-lg-8 col-md-6 col-12 m-t-20-sm pd-l-0">
                <h4 class="text-left white-text">Sin documentos, sin compromiso. </h4>
            </div>
        </div>
    </div>
</section>
